import { ModernMiddleBodyLayout1Wrapper } from './ModernMiddleBodyLayout1.styles';
import Heading from '../../../../CommonComponents/Heading/Heading';
import Button from '../../../../CommonComponents/Button/Button';
import Description from '../../../../CommonComponents/Description/Description';
import RenderMap from '../../RenderMap';
export const ModernMiddleBodyLayout1 = (props) => {
  const { middleBodyMapState, propStyle, mapLatLong } = props;
  return (
    <ModernMiddleBodyLayout1Wrapper
      className={`map${middleBodyMapState?.mapData?.mapType}_container`}
      themeStyle={propStyle}
    >
      <div className="top_section">
        <div className="top_section_left">
          {middleBodyMapState?.heading?.text && (
            <Heading headingData={middleBodyMapState?.heading} />
          )}
          {middleBodyMapState?.description?.text && (
            <Description descriptionData={middleBodyMapState?.description} />
          )}
        </div>
        <div className="top_section_right">
          {middleBodyMapState?.button?.content?.map((btnData, idx) => (
            <Button
              key={btnData?.id || idx}
              type={middleBodyMapState?.button?.type}
              btnData={{
                ...btnData,
                actions: {
                  ...btnData?.actions,
                  handler: middleBodyMapState?.mapData?.url,
                },
              }}
              primaryButton={propStyle?.buttonPrimary}
              secondaryButton={propStyle?.buttonSecondary}
              handler={middleBodyMapState?.mapData?.url}
            />
          ))}
        </div>
      </div>
      <div className="bottom_section">
        <RenderMap {...mapLatLong} />
      </div>
    </ModernMiddleBodyLayout1Wrapper>
  );
};
