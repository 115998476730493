import React, { useEffect, useState } from 'react';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { MapMiddleBodyParentContainer } from './ModernMiddleBodyMaps.styles';
import { ModernMiddleBodyLayout1 } from './Layout/ModernMiddleBodyLayout1/ModernMiddleBodyLayout1';
import { ModernMiddleBodyLayout2 } from './Layout/ModernMiddleBodyLayout2/ModernMiddleBodyLayout2';
import { ModernMiddleBodyLayout3 } from './Layout/ModernMiddleBodyLayout3/ModernMiddleBodyLayout3';
import { MAP_URL, extractLatLngFromGoogleMapsLink } from '../../utils/common';
import { useSelector } from 'react-redux';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernMiddleBodyMaps(props) {
  const [middleBodyMapState, setMiddleBodyMapState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [mapLatLong, setMapLatLong] = useState(
    extractLatLngFromGoogleMapsLink(middleBodyMapState?.mapData?.url || MAP_URL)
  );
  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (props?.config?.children) {
      setMiddleBodyMapState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const renderMiddleBodyType = (middleBodyData) => {
    switch (middleBodyData?.mapData?.mapType) {
      case 1:
      case 4:
        return (
          <ModernMiddleBodyLayout1
            middleBodyMapState={middleBodyData}
            mapLatLong={mapLatLong}
            propStyle={propStyle}
          />
        );
      case 2:
      case 3:
        return (
          <ModernMiddleBodyLayout2
            middleBodyMapState={middleBodyData}
            mapLatLong={mapLatLong}
            propStyle={propStyle}
          />
        );
      case 5:
      case 6:
        return (
          <ModernMiddleBodyLayout3
            middleBodyMapState={middleBodyData}
            mapLatLong={mapLatLong}
            propStyle={propStyle}
          />
        );
    }
  };
  useEffect(() => {
    setMapLatLong(
      extractLatLngFromGoogleMapsLink(middleBodyMapState?.mapData?.url || MAP_URL)
    );
  }, [middleBodyMapState?.mapData]);
  return (
    <MapMiddleBodyParentContainer
      id="MT__MIDDLEBODYMAP1"
      styleConfig={styleConfig?.config}
      propsBackground={middleBodyMapState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody_map bg_type_${middleBodyMapState?.media?.background?.type}`}
    >
      {renderMiddleBodyType(middleBodyMapState)}
    </MapMiddleBodyParentContainer>
  );
}

export default ModernMiddleBodyMaps;
