import { ModernMiddleBodyLayout3Wrapper } from './ModernMiddleBodyLayout3.styles';
import Heading from '../../../../CommonComponents/Heading/Heading';
import Button from '../../../../CommonComponents/Button/Button';
import Description from '../../../../CommonComponents/Description/Description';
import RenderMap from '../../RenderMap';
export const ModernMiddleBodyLayout3 = (props) => {
  const { middleBodyMapState, propStyle, mapLatLong } = props;
  return (
    <ModernMiddleBodyLayout3Wrapper
      className={`map${middleBodyMapState?.mapData?.mapType}_container`}
      themeStyle={propStyle}
    >
      <div className="top_section">
        {middleBodyMapState?.heading?.text && (
          <Heading headingData={middleBodyMapState?.heading} />
        )}
        {middleBodyMapState?.description?.text && (
          <Description descriptionData={middleBodyMapState?.description} />
        )}
      </div>
      <div className="bottom_section">
        <RenderMap {...mapLatLong} />

        <div className="bottom_section_bottom">
          {middleBodyMapState?.button?.content?.map((btnData, idx) => (
            <Button
              key={btnData?.id || idx}
              type={middleBodyMapState?.button?.type}
              btnData={{
                ...btnData,
                actions: {
                  ...btnData?.actions,
                  handler: middleBodyMapState?.mapData?.url,
                },
              }}
              primaryButton={propStyle?.buttonPrimary}
              secondaryButton={propStyle?.buttonSecondary}
            />
          ))}
        </div>
      </div>
    </ModernMiddleBodyLayout3Wrapper>
  );
};
