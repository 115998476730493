import { BODY_COLOR_OPACITY } from '@/components/DesignSystem/ModernTheme/utils/common';
import styled from 'styled-components';

export const ModernMiddleBodyLayout3Wrapper = styled.div`
  width: 100%;

  &.map5_container {
    display: flex;
    flex-direction: column;

    &.map5_container > .top_section {
      display: flex;
      flex-direction: column;
      flex: 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 1rem;
    }
    &.map5_container > .bottom_section {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &.map5_container > .top_section > h1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 0;
      color: ${(props) => props?.themeStyle?.heading?.color};
      font-family: ${(props) => props?.themeStyle?.heading?.fontFamily || 'Arial'};
      text-align: ${(props) => props?.themeStyle?.heading?.textAlign || 'center'};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map5_container > .top_section > p {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      font-style: normal;
      margin: 0;
      color: ${(props) => props?.themeStyle?.body?.color};
      text-align: ${(props) => props?.themeStyle?.body?.textAlign || 'center'};
      font-family: ${(props) => props?.themeStyle?.body?.fontFamily || 'Arial'};
      opacity: ${BODY_COLOR_OPACITY};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map5_container > .bottom_section > .bottom_section_bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      align-self: center;
    }
    @media screen and (min-width: 768px) {
      padding: 4rem;
      gap: 2rem;
      &.map5_container > .top_section > h1 {
        font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
        font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
        line-height: 3.875rem;
      }
      &.map5_container > .top_section > p {
        font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
        font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
        line-height: 1.75rem;
      }
      &.map5_container > .bottom_section {
        gap: 4rem;
      }
      &.map5_container > .bottom_section iframe {
        height: 564px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 2rem 1rem;
      gap: 1rem;
      &.map5_container > .top_section > h1 {
        font-size: 1.5rem;
        line-height: normal;
      }
      &.map5_container > .top_section > p {
        font-size: 0.875rem;
        line-height: normal;
      }
      &.map5_container > .bottom_section {
        gap: 1rem;
      }
      &.map5_container > .bottom_section > .bottom_section_bottom {
        width: 100%;
      }
      &.map5_container > .bottom_section > .bottom_section_bottom button {
        width: 100%;
      }
      &.map5_container > .bottom_section iframe {
        height: 226px;
      }
    }
  }

  &.map6_container {
    display: flex;
    flex-direction: column;

    &.map6_container > .top_section {
      display: flex;
      flex-direction: column;
      flex: 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 1rem;
    }
    &.map6_container > .bottom_section {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &.map6_container > .top_section > h1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 0;
      color: ${(props) => props?.themeStyle?.heading?.color};
      font-family: ${(props) => props?.themeStyle?.heading?.fontFamily || 'Arial'};
      text-align: ${(props) => props?.themeStyle?.heading?.textAlign || 'center'};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map6_container > .top_section > p {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      font-style: normal;
      margin: 0;
      color: ${(props) => props?.themeStyle?.body?.color};
      text-align: ${(props) => props?.themeStyle?.body?.textAlign || 'center'};
      font-family: ${(props) => props?.themeStyle?.body?.fontFamily || 'Arial'};
      opacity: ${BODY_COLOR_OPACITY};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map6_container > .bottom_section > .bottom_section_bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      align-self: center;
    }
    @media screen and (min-width: 768px) {
      padding: 4rem 0;
      gap: 2rem;
      &.map6_container > .top_section {
        padding: 0 4rem;
      }
      &.map6_container > .top_section > h1 {
        font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
        font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
        line-height: 3.875rem;
      }
      &.map6_container > .top_section > p {
        font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
        font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
        line-height: 1.75rem;
      }
      &.map6_container > .bottom_section {
        gap: 4rem;
      }
      &.map6_container > .bottom_section iframe {
        height: 564px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 2rem 0rem;
      gap: 1rem;
      &.map6_container > .top_section {
        padding: 0 1rem;
      }
      &.map6_container > .top_section > h1 {
        font-size: 1.5rem;
        line-height: normal;
      }
      &.map6_container > .top_section > p {
        font-size: 0.875rem;
        line-height: normal;
      }
      &.map6_container > .bottom_section {
        gap: 1rem;
      }
      &.map6_container > .bottom_section > .bottom_section_bottom {
        width: 100%;
        padding: 1rem;
      }
      &.map6_container > .bottom_section > .bottom_section_bottom button {
        width: 100%;
      }
      &.map6_container > .bottom_section iframe {
        height: 226px;
      }
    }
  }
`;
