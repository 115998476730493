import { FALLBACK_COLOR } from '../../utils/common';
import { getGoogleMapUrl } from '@/utils/getGoogleMapUrl';
const RenderMap = (props) => (
  <iframe
    width="100%"
    title="Address"
    loading="lazy"
    src={getGoogleMapUrl(props?.latitude, props?.longitude)}
    style={{ background: FALLBACK_COLOR }}
  />
);

export default RenderMap;
