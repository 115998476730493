import { BODY_COLOR_OPACITY } from '@/components/DesignSystem/ModernTheme/utils/common';
import styled from 'styled-components';

export const ModernMiddleBodyLayout2Wrapper = styled.div`
  width: 100%;

  &.map2_container {
    display: flex;

    &.map2_container > .left_section {
      display: flex;
      flex-direction: column;
    }
    &.map2_container > .right_section {
    }
    &.map2_container > .left_section > .left_section_top {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &.map2_container > .left_section > .left_section_top h1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin: 0;
      color: ${(props) => props?.themeStyle?.heading?.color};
      font-family: ${(props) => props?.themeStyle?.heading?.fontFamily || 'Arial'};
      text-align: ${(props) => props?.themeStyle?.heading?.textAlign || 'left'};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map2_container > .left_section > .left_section_top p {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      font-style: normal;
      margin: 0;
      color: ${(props) => props?.themeStyle?.body?.color};
      text-align: ${(props) => props?.themeStyle?.body?.textAlign || 'left'};
      font-family: ${(props) => props?.themeStyle?.body?.fontFamily || 'Arial'};
      opacity: ${BODY_COLOR_OPACITY};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map2_container > .left_section > .left_section_bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      align-self: flex-start;
    }
    @media screen and (min-width: 768px) {
      padding: 4rem;
      gap: 4rem;
      &.map2_container > .left_section {
        gap: 4rem;
        width: 50%;
        align-self: center;
      }
      &.map2_container > .right_section {
        width: 50%;
      }
      &.map2_container > .left_section > .left_section_top h1 {
        font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
        font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
        line-height: 3.875rem;
      }
      &.map2_container > .left_section > .left_section_top p {
        font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
        font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
        line-height: 1.75rem;
      }
      &.map2_container > .right_section iframe {
        height: 564px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 2rem 1rem;
      gap: 2.5rem;
      flex-direction: column;
      &.map2_container > .left_section {
        width: 100%;
        gap: 1rem;
      }
      &.map2_container > .right_section {
        width: 100%;
      }
      &.map2_container > .left_section > .left_section_top h1 {
        font-size: 1.5rem;
        line-height: normal;
      }
      &.map2_container > .left_section > .left_section_top p {
        font-size: 0.875rem;
        line-height: normal;
      }
      &.map2_container > .left_section > .left_section_bottom {
        width: 100%;
      }
      &.map2_container > .left_section > .left_section_bottom button {
        width: 100%;
      }
      &.map2_container > .right_section iframe {
        height: 226px;
      }
    }
  }

  &.map3_container {
    display: flex;

    &.map3_container > .left_section {
      display: flex;
      flex-direction: column;
    }
    &.map3_container > .right_section {
    }
    &.map3_container > .left_section > .left_section_top {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &.map3_container > .left_section > .left_section_top h1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin: 0;
      color: ${(props) => props?.themeStyle?.heading?.color};
      font-family: ${(props) => props?.themeStyle?.heading?.fontFamily || 'Arial'};
      text-align: ${(props) => props?.themeStyle?.heading?.textAlign || 'left'};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map3_container > .left_section > .left_section_top p {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      font-style: normal;
      margin: 0;
      color: ${(props) => props?.themeStyle?.body?.color};
      text-align: ${(props) => props?.themeStyle?.body?.textAlign || 'left'};
      font-family: ${(props) => props?.themeStyle?.body?.fontFamily || 'Arial'};
      opacity: ${BODY_COLOR_OPACITY};
      overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
      overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
    }
    &.map3_container > .left_section > .left_section_bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      align-self: flex-start;
    }
    @media screen and (min-width: 768px) {
      gap: 4rem;
      &.map3_container > .left_section {
        gap: 4rem;
        width: 50%;
        align-self: center;
        padding: 0 0 0 4rem;
      }
      &.map3_container > .right_section {
        width: 50%;
      }
      &.map3_container > .left_section > .left_section_top h1 {
        font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
        font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
        line-height: 3.875rem;
      }
      &.map3_container > .left_section > .left_section_top p {
        font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
        font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
        line-height: 1.75rem;
      }
      &.map3_container > .right_section iframe {
        height: 564px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 2rem 0;
      gap: 2.5rem;
      flex-direction: column;
      &.map3_container > .left_section {
        width: 100%;
        gap: 1rem;
        padding: 0 1rem;
      }
      &.map3_container > .right_section {
        width: 100%;
      }
      &.map3_container > .left_section > .left_section_top h1 {
        font-size: 1.5rem;
        line-height: normal;
      }
      &.map3_container > .left_section > .left_section_top p {
        font-size: 0.875rem;
        line-height: normal;
      }
      &.map3_container > .left_section > .left_section_bottom {
        width: 100%;
      }
      &.map3_container > .left_section > .left_section_bottom button {
        width: 100%;
      }
      &.map3_container > .right_section iframe {
        height: 226px;
      }
    }
  }
`;
